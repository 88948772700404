'use strict';

(function() {
  
  //
  // Variables
  //

  var search_form = '#app-search-form';
  var search_results = '#app-search-results';
  var search_results_loader = '#app-search-results-loader';

  //
  // Function
  //

  function init_search_form(search_form = $(search_form).find(form)) {
    // handle submit
    search_form.submit(
      function(e) {
        e.preventDefault();
        handle_submit(search_form);
      }
    );
    // initial submit
    handle_submit(search_form);
  }

  function handle_submit(search_form) {
    var search_term = search_form.find('input#search_term').val();
     if (search_term != '') {
      $(search_results).html('');
      $(search_results_loader).show();
      load_search_results(search_term);
    }
  }

  function load_search_results(search_term = "", results_container = $(search_results)) {
    Rails.ajax({ 
      url: results_url(search_term),
      type: 'get',
      error: function(data) {
        console.log('Cannot fetch results from server.');
      },
      success: function(data) {
        var results_as_html = data.body.innerHTML;
        $(search_results_loader).hide();
        results_container.html(results_as_html);
      }
    })
  }

  function results_url(search_term) {
    return encodeURI('/app/search/results?term=' + search_term)
  }

  //
  // Event
  //

  // when document is loaded
  $(document).ready(function() {
    if ($(search_form).length != 0) { init_search_form($(search_form)); }
  });

  // when modal shows
  $(window).on('shown.bs.modal', function() {
    if ($(search_form).length != 0) { init_search_form($(search_form)); }
  });



})();