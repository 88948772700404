

(function () {
  var $doc = $(document)

  $doc.on('ajax:send', 'form[data-remote=true]', function () {

    var $form = $(this)
    var $button = $form.parent().parent().find('[data-disable-with]:not(.hidden)');
    if (!$button.length) return

    // set button in success mode after successful submission

    $form.on('ajax:success', function () {
      // Use setTimeout to prevent race-condition when Rails re-enables the button
      setTimeout(function () {
        $button.each(function () {
          $(this)
            .removeClass(['btn-primary', 'btn-secondary'])
            .addClass('btn-success')
            .attr('disabled', true)
            .val($(this).attr('data-success-with'));
        })
      }, 0)
    })

    // set button in error mode after error 500

    $form.on('ajax:error', function (evt, xhr, status, error) {
      console.log('error!');
      // Use setTimeout to prevent race-condition when Rails re-enables the button
      setTimeout(function () {
        $button.each(function () {
          $(this)
            .removeClass(['btn-primary', 'btn-secondary'])
            .addClass('btn-danger')
            .attr('disabled', true)
            .val($(this).attr('data-error-with'));
        })
      }, 0)
    });

    // prevents rails-ujs to early re-enable submit button when the form request succeed
    // https://stackoverflow.com/a/46844912
    //
    // $form.on('ajax:complete', function () {
    // // Use setTimeout to prevent race-condition when Rails re-enables the button
    // setTimeout(function () {
    //   $button.each(function () {
    //     // Rails.disableElement(this)
    //   })
    // }, 0)
    // })

    // Prevent button from being cached in disabled state
    //
    // $doc.one('turbolinks:before-cache', function () {
    //   $button.each(function () { Rails.enableElement(this) })
    // })
  })
})()
