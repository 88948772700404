// Rails

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Jquery & Bootstrap

import 'jquery'
import 'popper.js'
import 'bootstrap'

// Dashkit

// - fonts
import '../fonts/Feather/Feather.css'
// - js
import '../js/dashkit/autosize'
import '../js/dashkit/dropdowns'
import '../js/dashkit/list' // Search, sort, filters, flexibility to tables, list and more!
import '../js/dashkit/navbar'
import '../js/dashkit/polyfills'
import '../js/dashkit/popover'
import '../js/dashkit/tooltip'
// - styles
import '../css/dashkit.scss'
// - chunks
// import '../js/components/autocomplete_places' // places.js
// import '../js/dashkit/dropzone' // drag'n'drop file uploads 118,67kb
// import '../js/dashkit/select2' // Query based replacement for select boxes. 144,1kb
// import '../js/dashkit/flatpickr' // lightweight and powerful datetime picker.
// import '../js/dashkit/kanban' // shopify draggable
// import '../js/dashkit/highlight' // Syntax highlighting for the Web
// import '../js/dashkit/quill' // powerful rich text editor
// import '../js/dashkit/map' // mapbox
// import '../js/dashkit/charts' // Open source HTML5 Charts for your website

// Core

import '../js/core/modal-form'
// import '../js/core/async-images'

// App

import '../js/app/search'
